<template>
  <div class="layout">
    <el-container>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "layout",
  data() {
    return {
      shop_name: "",
      logo: "",
    };
  },

  created() {

    this.shop_name = localStorage.getItem("shop_name");

    // this.getBreadcrumb();
  },
  watch: {
    // $route() {
    // 	this.getBreadcrumb()
    // }
  },
  methods: {
    clearadd() {
      localStorage.clear();
      this.$router.replace("/login");
    },

    goTo(path) {
      console.log(path);
      this.$router.replace(path);
    },
    // getBreadcrumb() {
    // 	// this.breadList = this.$route.matched.filter(item => item !== this.$route.matched[0]);
    // 	// this.breadList.splice(0, 1)
    // 	// console.log(this.breadList)

    // 	let route = this.$route.matched[1]
    // 	let bred = this.breadList;
    // 	bred.push({
    // 		path: route.path,
    // 		name: route.name
    // 	})
    // 	console.log(bred)
    // 	this.breadList = bred.slice(0, bred.findIndex(res => res.name === route.name) + 1)
    // 	// this.breadList
    // 	// console.log(this.$route.matched)
    // 	console.log(this.breadList)

    // },

    /**
     * @param {Object} userFileParentId
     */

    // cardFolderPrevious: function (userFileParentId) {
    //       this.fileCard.cardBreadcrumbs = this.fileCard.cardBreadcrumbs.slice(0, this.fileCard.cardBreadcrumbs
    //         .findIndex(res => res.userFileId === userFileParentId) + 1)
    //     },
    // cardFolderNext: function (item) {
    //       // 增加面包屑导航数据
    //       this.fileCard.cardBreadcrumbs.push({
    //         userFileId: item.userFileId,
    //         userFileName: item.userFileName
    //       })
    //     },
  },
};
</script>
<style scoped>
.el-main {
  /* padding: 0 20px; */
  height: 100vh;
	box-sizing: border-box;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}

.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}

.container_login {
  float: right;
  text-align: left;
  color: #fff;
  position: relative;
  left: -20px;
}

.container_login img {
  width: 40px;
  height: 40px;
  position: relative;
  top: 15px;
  left: -10px;
}

.top_logo {
  height: 50px;
  position: relative;
  top: 5px;
  /* left: 80px; */
}

.el-header {
  padding: 0;
}

.bread_top {
  width: 100%;
  height: 40px;
  background: #fff;
  position: relative;
  top: -15px;
}

.el-breadcrumb {
  line-height: 40px;
}

.el-menu {
  border-right: solid 0px #e6e6e6;
}

.el-col-12 {
  width: 15%;
}

.el-menu-item:focus,
.el-menu-item:hover {
  outline: 0;
  color: #fff;
  background-color: #2b80ff;
}

.el-submenu__title:hover {
  color: #fff;
  background-color: #2b80ff;
}


.el-container{
  width: 100vw;
  height: 100vh;
}
</style>
